import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React, { PropsWithChildren } from 'react';

interface PageHeaderProps {
  backgroundImage: IGatsbyImageData;
}

/**
 *  Subpage header wrapper with a background image
 */
const PageHeader = ({
  backgroundImage,
  children,
}: PropsWithChildren<PageHeaderProps>) => {
  const bgImage = convertToBgImage(backgroundImage);
  return (
    <BackgroundImage {...bgImage}>
      <div className="bg-black bg-opacity-60 py-16">{children}</div>
    </BackgroundImage>
  );
};

export default PageHeader;
